<template>
	<div>
		<a-form ref="form" :model="formState" name="form" style="margin-top: 30px;" scrollToFirstError 
			 @finish="onSearch">
			<a-row justify="center">
				<a-form-item class="ui-form__item" label="商品核销码" name="verificationCode" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input ref="codeInput" v-model:value="formState.verificationCode" placeholder="请输入或扫描核销码"/>
				</a-form-item>
				<a-button type="primary" html-type="submit">查询</a-button>
				<a-button style="margin-left: 10px" @click="reset">重置</a-button>
			</a-row>
		</a-form>
		<a-spin :spinning="loading">
			<div v-if="list.length">
				<a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="id" :pagination="pagination" :scroll="{ x: 2400 }">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key == 'storeInfo'">
							<div>{{record.orderStoreVO.name || '--'}} </div>
						</template>

						<template v-if="column.key === 'tradeInfo'">
							<div>交易单号：<span>{{ record.outTradeNo }}</span></div>
							<div>交易状态：<span>{{ transOrderStatus(record.status)}}</span></div>
							<div>账单金额：<span style="color: orange;">￥{{ record.totalPrice }}</span></div>
							<div>账单时间：<span style="color: orange;">{{ transDateTime(record.createTime) }}</span></div>
						</template>
						
						<template v-else-if="column.key === 'userInfo'">
							<div v-if="record.orderVerifier">
								<div>{{ record.orderVerifier.nickName}}</div>
								<div>{{ record.orderVerifier.userPhone}}</div>
							</div>
							<div v-else>--</div>
						</template>
						
						<template v-else-if="column.key === 'goodsInfo'">
							<div style="display: flex;align-items: flex-start;" v-for="item in record.goodsInfoVOS"
								:key="item.id">
								<a-image style="width: 60px;border-radius: 2px;" :src="item.imgUrl.split(',')[0]"></a-image>
								<div style="margin-left: 8px;">
									<div>{{ item.mainTitle }}</div>
									<div style="font-size: 18px;color: orange;">￥{{ item.nowPrice }}</div>
								</div>
							</div>
						</template>
						
						<template v-else-if="column.key === 'cardInfo'">
							<div v-if="record.goodsInfoVOS[0].isCard">
								<a-tag color="orange" style="color: #fa8c16;">
									{{ ['次卡', '年卡'][record.goodsInfoVOS[0].cardType - 1] }} </a-tag>
								<div>{{ record.code }}</div>
								<div v-if="record.goodsInfoVOS[0].cardType === 1">
									开卡次数：{{ record.goodsInfoVOS[0].cardBalanceTimes }}</div>
								<!-- <div>赠送次数：{{ record.cardGiftTimes }}</div> -->
							</div>
							<div v-if="record.goodsInfoVOS[0].isRechargeCard">
								<a-divider style="margin: 6px 0;" v-if="record.goodsInfoVOS[0].isCard"/>
								<a-tag color="cyan" style="color: #2db7f5;">线上会员卡</a-tag>
								<div v-if="record.rechargeCardTitle">卡等级：{{ record.rechargeCardTitle }}</div>
								<div v-if="record.rechargeCardNumber">卡号：{{ record.rechargeCardNumber }}</div>
								<div>初始金额：{{ record.initAmount }}元</div>
							</div>
							<div v-if="!record.goodsInfoVOS[0].isCard && !record.goodsInfoVOS[0].isRechargeCard">--</div>
						</template>

						<template v-else-if="column.key === 'couponInfo'">
							<div v-if="record.goodsInfoVOS[0].isCoupon">
								<div style="margin-bottom: 5px;" v-if="record.goodsInfoVOS[0].ticketBatchNum">
									<div>兑换券批次号：<span style="color: rgb(102, 102, 102);">{{record.goodsInfoVOS[0].ticketCouponList.length ? record.goodsInfoVOS[0].ticketCouponList[0].batchNo : '' }}</span></div>
									<div>兑换券发放数量：<span style="color: rgb(102, 102, 102);">{{record.goodsInfoVOS[0].ticketBatchNum}}张</span></div>
									<div>
										兑换券号：
										<a-tag v-for="(item, i) in record.goodsInfoVOS[0].ticketCouponList" :key="i">
											{{ item.barcode }}
										</a-tag>
									</div>
								</div>
								<div v-if="record.goodsInfoVOS[0].snackBatchNum">
									<div>卖品券批次号：<span style="color: rgb(102, 102, 102);">{{record.goodsInfoVOS[0].snackCouponList.length ? record.goodsInfoVOS[0].snackCouponList[0].batchNo : '' }}</span></div>
									<div>卖品券发放数量：<span style="color: rgb(102, 102, 102);">{{record.goodsInfoVOS[0].snackBatchNum}}张</span></div>
									<div>
										卖品券号：
										<a-tag v-for="(item, i) in record.goodsInfoVOS[0].snackCouponList" :key="i">
											{{ item.barcode }}
										</a-tag>
									</div>
								</div>
							</div>
							<span v-else>--</span>
						</template>

						<template v-else-if="column.key === 'deliveryInfo'">
							<div style="text-align: center;">
								<div>{{ ['无', '自提', '快递'][record.deliveryType - 1] }}</div>
								<div :id="'copys' + index">{{record.verificationCode}}</div>
								<a v-if="record.verificationCode" class="ui-copy__btn" @click="onCopy(index)">复制核销码</a>
							</div>
						</template>
						
						<template v-else-if="column.key === 'statusInfo'">
							<div v-if="record.deliveryType !== 3">
								核销状态：<span style="color: #42B983;">{{ transOrderStatus(record.status)}}</span>
								<div v-if="record.verificationUserName">
									<div>核销人员：{{ record.verificationUserName }}</div>
									<div>{{ transDateTime(record.finishTime) }}</div>
								</div>
							</div>
							<div v-else>
								发货状态：<span style="color: #42B983;">{{ transOrderStatus(record.status)}}</span>
								<div>运费：{{ record.deliveryFee }}</div>
								<div v-if="record.sendGoodsUserName">
									<div>发货人员：{{ record.sendGoodsUserName }}</div>
									<div>{{ transDateTime(record.sendGoodsTime) }}</div>
								</div>
							</div>
							<div>{{ transDateTime(record.signTime) }}</div>
						</template>
						
						<template v-else-if="column.key === 'receiptInfo'">
							<span v-if="record.deliveryType === 1">--</span>
							<div v-else-if="record.deliveryType === 2">
								<div>{{ record.orderStoreVO.name }}</div>
								<div>{{ record.orderStoreVO.address }}</div>
							</div>
							<div v-else>
								<div>{{ record.orderAddress.name }}</div>
								<div>{{ record.orderAddress.phone }}</div>
								<div>{{ record.orderAddress.showAddress }}</div>
							</div>
						</template>
						
						<template v-else-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						
						<template v-else-if="column.key === 'action'">
							<div v-if="record.deliveryType === 2">
								<a v-permission="['verify_sale_manage_goods_confirm']" v-if="record.status === 'WAIT_CHECK'" @click="onVerification(record.orderId)">确认核销</a>
								<a v-if="record.status === 'TRADE_FINISH'" disabled style="color: #CCC;">已核销</a>
							</div>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {searchOrderVerification} from '@/service/modules/order.js';
	import {verificationOrderBase} from '@/service/modules/mall.js';
	export default {
		components: {
			Icon,
		},
		data() {
			return {
				loading: false,
				formState: {},
                searchData: [],
				list: [],
				columns: [{
					title: '商城名称',
					key: 'storeInfo'
				}, {
					title: '交易信息',
					key: 'tradeInfo'
				}, {
					title: '会员信息',
					key: 'userInfo'
				},, {
					title: '商品信息',
					key: 'goodsInfo'
				}, {
					title: '卡信息',
					key: 'cardInfo'
				}, {
					title: '券信息',
					key: 'couponInfo'
				}, {
					title: '配送方式',
					key: 'deliveryInfo',
					align: 'center'
				}, {
					title: '发货/核销信息',
					key: 'statusInfo'
				}, {
					title: '收货信息',
					key: 'receiptInfo'
				}, {
					title: '交易时间',
					width: 180,
					key: 'createTime'
				}, {
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created(){
			this.inputFocus();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
            async getData(){
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					saleType: 1,
					...this.searchData
				}
				this.loading = true;
				try {
					let {data} = await searchOrderVerification(postData);
					this.loading = false;
					this.list = data.list;
					this.pagination.total = data.totalCount; 
				} catch (error) {
					this.loading = false;
				}
			},
			onVerification(orderId) {
				this.$confirm({
					title: '提示',
					content: '确定完成核销吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let res = await verificationOrderBase({
								orderId,
							});
							this.loading = false;
							if (res.code === 200) {
								this.$message.success('核销成功');
								this.getData();
							}
						} catch (error) {
							this.loading = false;
						}
					}
				})
			},
			onCopy(index) {
				const range = document.createRange();
				range.selectNode(document.querySelector("#copys" + index));
				const selection = window.getSelection();
				if (selection.rangeCount > 0) selection.removeAllRanges();
				selection.addRange(range);
				document.execCommand('Copy');
				this.$message.success('复制成功');
			},
			reset() {
				this.$refs.form.resetFields();
				this.list = [];
			},
			transOrderStatus(status){
				const orderStatus = {
					'WAIT_BUYER_PAY': '待支付',
					'SYSTEM_CANCEL': '系统超时取消',
					'CUSTOMER_CANCEL': '用户自行取消',
					'WAIT_SELLER_SEND_GOODS': '待发货',
					'WAIT_CHECK': '待核销',
					'TRADE_FINISH': '已核销',
					'WAIT_BUYER_CONFIRM_GOODS': '待收货',
					'AFTERSALE_FINISH': '交易关闭',
					'CHECKOUT_SUCCESS': '已付款',
					'TRADE_FINISH': '订单完成',
				}
				return orderStatus[status];
			},
			inputFocus() {
				this.$nextTick(() => {
					this.$refs.codeInput.focus()
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		width: 500px;
		margin-right: 30px;
	}
</style>
